/*

  MODX/BASSCSS

  CREATE BY: Wayne Roddy (wayne@modx.com)

  Styles are Desktop First
   - USE BREAKPOINTS:
     @include respond-to($mobile){ }
     @include respond-to-above($mobile){ }
   - .clearfix goes on the wrapper not after last float

*/

body,
button,
input,
select,
textarea,
pre,
ul,
ol {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
ol,
ul,
dt,
dd {
	margin: 0;
}

ul,
ol {
	list-style: none;
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
	display: block;
}

img {
	border: none;
}

/*! sanitize.css | CC0 Public Domain | github.com/jonathantneal/sanitize.css */

/*
 * Normalization
 */

:root {
	-ms-overflow-style: -ms-autohiding-scrollbar;
	overflow-y: scroll;
	text-size-adjust: 100%;
}

audio:not([controls]) {
	display: none;
}

details {
	display: block;
}

input[type="number"] {
	width: auto;
}

input[type="search"] {
	-webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

main {
	display: block;
}

summary {
	display: block;
}

pre {
	overflow: auto;
}

progress {
	display: inline-block;
}

template {
	display: none;
}

textarea {
	overflow: auto;
}

[hidden] {
	display: none;
}

[unselectable] {
	user-select: none;
}

/*
 * Universal inheritance
 */

*,
::before,
::after {
	box-sizing: inherit;
}

* {
	font-size: inherit;
	line-height: inherit;
}

::before,
::after {
	text-decoration: inherit;
	vertical-align: inherit;
}

/*
 * Opinionated defaults
 */

*,
::before,
::after {
	border-style: solid;
	border-width: 0;
}

* {
	margin: 0;
	padding: 0;
}

:root {
	box-sizing: border-box;
	cursor: default;
	font: 16px/1.5 sans-serif;
	text-rendering: optimizeLegibility;
}

a {
	text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

button,
input,
select,
textarea {
	color: inherit;
	font-family: inherit;
	font-style: inherit;
	font-weight: inherit;
}

button,
input,
select,
textarea {
	min-height: 1.5em;
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
}

select::-ms-expand {
	display: none;
}

select::-ms-value {
	color: currentColor;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

textarea {
	resize: vertical;
}

.measure {
	max-width: 48rem;
}

.measure-narrow {
	max-width: 32rem;
}

.measure-wide {
	max-width: 60rem;
}

body {
	font-family: 'Roboto', Helvetica, sans-serif;
	line-height: 1.5rem;
	font-weight: 400;
}

button,
.button,
#promo-slides,
.news-trigger {
	font-family: 'Montserrat', sans-serif;
}

h1,
.h1,
.f1 {
	font-size: 3rem;
	line-height: 3rem;
	font-weight: 500;
}

h2,
.h2,
.f2 {
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 500;
}

h3,
.h3,
.f3 {
	font-size: 1.5rem;
	line-height: 1.65rem;
	font-weight: 500;
}

h4,
.h4,
.f4 {
	font-size: 1.25rem;
	line-height: 1.5rem;
}

p,
.p,
h5,
h6,
dl,
ol,
ul {
	font-size: 1rem;
	line-height: 1.5rem;
}

small,
.small,
.f5 {
	font-size: .75rem;
	line-height: 1.5rem;
}

strong,
.bold {
	font-weight: bold;
}

.regular {
	font-weight: normal;
}

.italic {
	font-style: italic;
}

.caps {
	text-transform: uppercase;
	letter-spacing: .2em;
}

.center {
	text-align: center;
}

.right-align {
	text-align: right;
}

.leading-1 {
	line-height: 1.5rem;
}

.leading-2 {
	line-height: 2rem;
}

.leading-3 {
	line-height: 3rem;
}

.leading-4 {
	line-height: 4rem;
}

.m0 {
	margin: 0;
}

.mb0 {
	margin-bottom: 0;
}

.m1 {
	margin: 1rem;
}

.mt1 {
	margin-top: 1rem;
}

.mr1 {
	margin-right: 1rem;
}

.mb1 {
	margin-bottom: 1rem;
}

.ml1 {
	margin-left: 1rem;
}

.m2 {
	margin: 2rem;
}

.mt2 {
	margin-top: 2rem;
}

.mr2 {
	margin-right: 2rem;
}

.mb2 {
	margin-bottom: 2rem;
}

.ml2 {
	margin-left: 2rem;
}

.m3 {
	margin: 3rem;
}

.mt3 {
	margin-top: 3rem;
}

.mr3 {
	margin-right: 3rem;
}

.mb3 {
	margin-bottom: 3rem;
}

.ml3 {
	margin-left: 3rem;
}

.m4 {
	margin: 4rem;
}

.mt4 {
	margin-top: 4rem;
}

.mr4 {
	margin-right: 4rem;
}

.mb4 {
	margin-bottom: 4rem;
}

.ml4 {
	margin-left: 4rem;
}

.m-responsive {
	margin: 4rem;
}

.mt-responsive {
	margin-top: 4rem;
}

.mr-responsive {
	margin-right: 4rem;
}

.mb-responsive {
	margin-bottom: 4rem;
}

.ml-responsive {
	margin-left: 4rem;
}

.p0 {
	padding: 0;
}

.quarter {
	padding: .25rem;
}

.phalf {
	padding: .5rem;
}

.p1 {
	padding: 1rem;
}

.px1 {
	padding-right: 1rem;
	padding-left: 1rem;
}

.py1 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.p2 {
	padding: 2rem;
}

.px2 {
	padding-right: 2rem;
	padding-left: 2rem;
}

.py2 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.p3 {
	padding: 3rem;
}

.px3 {
	padding-right: 3rem;
	padding-left: 3rem;
}

.py3 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.p4 {
	padding: 4rem;
}

.px4 {
	padding-right: 4rem;
	padding-left: 4rem;
}

.py4 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.p-responsive {
	padding: 4rem;
}

.px-responsive {
	padding-right: 4rem;
	padding-left: 4rem;
}

.py-responsive {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.inline {
	display: inline;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.oh {
	overflow: hidden;
}

.left {
	float: left;
}

.right {
	float: right;
}

.clearfix:before,
header:before,
main:before,
footer:before,
.mp-grid:before,
.clearfix:after,
header:after,
main:after,
footer:after,
.mp-grid:after {
	content: " ";
	display: table;
}

.clearfix:after,
header:after,
main:after,
footer:after,
.mp-grid:after {
	clear: both;
}

.fit {
	max-width: 100%;
}

.wrap {
	margin-right: auto;
	margin-left: auto;
}

.vertical-center {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.mobile-show,
.tablet-show {
	display: none;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

.t0 {
	top: 0;
}

.r0 {
	right: 0;
}

.b0 {
	bottom: 0;
}

.l0 {
	left: 0;
}

.z9 {
	z-index: 99999;
}

.z8 {
	z-index: 8000;
}

.z7 {
	z-index: 7000;
}

.z6 {
	z-index: 6000;
}

.z5 {
	z-index: 5000;
}

.z4 {
	z-index: 4000;
}

.z3 {
	z-index: 3000;
}

.z2 {
	z-index: 2000;
}

.z1 {
	z-index: 1000;
}

.z0 {
	z-index: 100;
}

.absolute-center {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	display: table;
}

/*
 * animations
 */

/*
 * colors
 */

/*
 * text colors
 */

.blue,
.text-primary {
	color: #0077C5;
}

.yellow,
.text-secondary,
.sisea-highlight {
	color: #FEC10D;
}

.white {
	color: #fff;
}

.black {
	color: #000;
}

.light-gray {
	color: #eeeeee;
}

.mid-gray,
.text-muted {
	color: #777;
}

.dark-gray {
	color: #333;
}

.red,
.text-danger {
	color: #ff1d08;
}

.green,
.text-success {
	color: #60dd4a;
}

.orange,
.text-warning {
	color: #dd9c00;
}

/*
 * solid background colors
 */

.bg-primary {
	background-color: #0077C5;
}

.bg-secondary {
	background-color: #FEC10D;
}

.bg-white {
	background-color: #FFF;
}

.bg-blue {
	background-color: #0077C5;
}

.bg-dark-blue {
	background-color: #001b2c;
}

.bg-light-gray {
	background-color: #eeeeee;
}

.bg-mid-gray {
	background-color: #777;
}

.bg-dark-gray {
	background-color: #333;
}

.bg-red {
	background-color: #ff1d08;
}

.bg-green {
	background-color: #60dd4a;
}

.bg-orange {
	background-color: #dd9c00;
}

/*
 * transparent background colors
 */

.bg-t-blue {
	background-color: rgba(0, 119, 197, 0.5);
}

.bg-t-red {
	background-color: rgba(255, 29, 8, 0.5);
}

.bg-t-green {
	background-color: rgba(96, 221, 74, 0.5);
}

.bg-t-orange {
	background-color: rgba(221, 156, 0, 0.5);
}

.bg-t-mid-gray {
	background-color: rgba(119, 119, 119, 0.5);
}

.bg-t-dark-gray {
	background-color: rgba(51, 51, 51, 0.5);
}

body {
	color: #FFF;
	background: #000 url("images/leather-bg.jpg") top center repeat-y;
}

a {
	color: #0077C5;
	text-decoration: none;
}

a:hover {
	color: #005892;
}

hr {
	height: 0;
	border: none;
	border-bottom: 1px solid #eeeeee;
}

blockquote {
	border-left: 6px solid #eeeeee;
	padding: 0.5rem 1rem;
	font-size: 1.15rem;
	line-height: 1.5rem;
	margin: 1rem 0;
}

.pointer {
	cursor: pointer;
}

.news-expand {
	display: none;
}

#topicTitle:first-letter {
	text-transform: uppercase;
}

.sisea-paging {
	margin-bottom: 1rem;
}

.sisea-results-list {
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	margin-bottom: 1rem;
}

.contactForm div {
	padding-bottom: 1rem;
}

.contactForm div label {
	width: 130px;
	display: inline-block;
}

.contactForm div .textarea {
	vertical-align: top;
}

.contactForm div .input,
.contactForm div .textarea {
	width: 450px;
	color: #0077C5;
}

.contactForm button {
	margin-left: 165px;
	margin-top: 1rem;
}

.form-light label {
	color: #FFF;
}

.form-light .input,
.form-light .textarea,
.form-light .select {
	transition: box-shadow 0.2s ease;
	border-color: #e1e1e1;
	color: #333;
	background-color: #FFF;
}

.form-light .input:focus,
.form-light .textarea:focus,
.form-light .select:focus {
	outline: none;
	border-color: #0077C5;
	background-color: #fcfcfc;
	box-shadow: 0 0 0.125rem rgba(0, 119, 197, 0.5);
}

.form-light .radio input,
.form-light .checkbox input {
	transition: box-shadow 0.2s ease;
}

.form-light .radio input:focus,
.form-light .checkbox input:focus {
	outline: none;
	box-shadow: 0 0 0 2px #fff, 0 0 1px 4px rgba(0, 119, 197, 0.5);
}

.form-light .radio input {
	border-radius: 50%;
}

table thead {
	background: #eeeeee;
	border-bottom: 1px solid #777;
}

table.border {
	border-color: #eeeeee;
}

table.highlight tbody tr:nth-child(even) {
	background-color: #eeeeee;
}

table.highlight tbody tr:hover {
	background-color: rgba(0, 119, 197, 0.5);
}

.hover-shadow:hover {
	box-shadow: 0px 2px 3px 3px #eeeeee;
}

.hover-glow:hover {
	box-shadow: 0px 0px 3px 3px #eeeeee;
}

.border {
	border-style: solid;
	border-width: 1px;
}

.border-top {
	border-top-style: solid;
	border-top-width: 1px;
}

.border-bottom {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.border-white {
	border-color: rgba(255, 255, 255, 0.5);
}

.border-light-gray {
	border-color: rgba(238, 238, 238, 0.5);
}

.rounded {
	border-radius: .25rem;
}

.rounded2 {
	border-radius: .5rem;
}

.transition-all {
	transition: all 0.2s ease;
}

.button {
	transition-duration: .2s;
	transition-timing-function: ease;
	transition-property: box-shadow , background-color;
	text-decoration: none;
	cursor: pointer;
	border: none;
	-webkit-appearance: none;
	appearance: none;
	white-space: nowrap;
	display: inline-block;
	line-height: 1rem;
	height: auto;
	min-height: 1rem;
	padding: 0.5rem 1rem;
	text-transform: uppercase;
}

.button:focus {
	outline: none;
}

.button-blue,
.button-primary {
	color: #FFF;
	background-color: #0077C5;
}

.button-blue:hover,
.button-primary:hover {
	color: #FFF;
	background-color: #00395f;
}

.button-red,
.button-danger {
	color: #FFF;
	background-color: #ff1d08;
}

.button-red:hover,
.button-danger:hover {
	color: #FFF;
	background-color: #a10e00;
}

.button-gray,
button:disabled,
input:disabled {
	color: #FFF;
	background-color: #777;
}

.button-gray:hover,
button:disabled:hover,
input:disabled:hover {
	color: #FFF;
	background-color: #444444;
}

button:disabled,
input:disabled {
	cursor: not-allowed;
}

.button-dark {
	color: #FFF;
	background-color: #333;
}

.button-dark:hover {
	color: #FFF;
	background-color: black;
}

fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

.form {
	font-size: 1rem;
}

.form label {
	min-width: 10rem;
	display: inline-block;
}

.form .input-wrapper {
	margin-bottom: 1rem;
}

.input {
	appearance: none;
	border-style: solid;
	border-width: 1px;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 100%;
	height: 2rem;
	line-height: 1rem;
	text-indent: .25rem;
	border-radius: .15rem;
}

.select {
	box-sizing: border-box;
	height: 2rem;
	margin-right: 0;
	margin-left: 0;
	border-radius: .15rem;
	padding-right: 1rem;
}

.textarea {
	box-sizing: border-box;
	padding: .5rem;
	border-style: solid;
	border-width: 1px;
	line-height: 1.5rem;
	border-radius: .15rem;
}

.form-stacked label {
	padding: .25rem;
}

.form-stacked label,
.form-stacked .input,
.form-stacked .select,
.form-stacked .textarea {
	display: block;
	width: 100%;
}

.form-stacked .input,
.form-stacked .select,
.form-stacked .radio,
.form-stacked .checkbox {
	margin-bottom: 1rem;
}

input[type="radio"],
input[type="radio"] + span,
input[type="checkbox"],
input[type="checkbox"] + span {
	vertical-align: middle;
}

ul {
	margin-left: 1rem;
	list-style-type: disc;
}

ul li ul {
	margin-left: 1rem;
}

ol {
	margin-left: 1rem;
	list-style: decimal;
}

ol li ol {
	margin-left: 1rem;
}

.list-simple {
	list-style: none;
	padding-left: 0;
}

.list-inline {
	list-style: none;
	padding-left: 0;
}

.list-inline li {
	display: inline;
	white-space: nowrap;
}

/*Layout Elements -

- MODULAR PERCENT LAYOUT
- header
- hero
- main

*/

.gutter {
	padding: .5rem;
}

.gutterx2 {
	padding: 1rem;
}

.full-width {
	width: 100%;
}

.three-quarter-width {
	width: 75%;
}

.half-width {
	width: 50%;
}

.third-width {
	width: 33.333%;
}

.quarter-width {
	width: 25%;
}

header {
	background-color: #0077C5;
}

header #logo {
	float: left;
	padding-top: 2rem;
}

header #logo a {
	color: #FFF;
	font-size: 2rem;
}

header .top--links {
	float: right;
	color: #FFF;
	text-align: right;
	font-size: .95rem;
	padding-top: .5rem;
}

header .top--links span {
	font-size: 1.3rem;
	padding-right: .3rem;
}

header .top--links a {
	color: #FFF;
}

header .top--links a:hover {
	color: #FEC10D;
}

.hero {
	height: 20rem;
	width: 100%;
	background-size: cover;
	border-bottom: 1px solid #FFF;
}

.hero .wrap {
	position: relative;
	z-index: 99;
	height: inherit;
}

.hero .wrap h1 {
	position: absolute;
	bottom: 0;
	font-size: 4rem;
}

main > .wrap {
	margin-top: 3rem;
	background: transparent url("images/main-pseudo-line.png") top right repeat-y;
}

main article {
	width: 65%;
	float: left;
	padding-bottom: 2rem;
}

main article p {
	padding-bottom: 1rem;
	line-height: 1.75rem;
}

main article ul,
main article ol {
	margin: 1rem 2rem;
}

main article h2,
main article h3,
main article h4 {
	padding-bottom: 1rem;
}

main aside {
	padding: 1rem 0 0 2rem;
	width: 30%;
	float: right;
}

footer {
	width: 100%;
	padding: 1.5rem 0;
	background-color: #333;
}

footer .wrap div {
	border-right: 1px solid #FFF;
	float: left;
	min-height: 110px;
}

footer .wrap div:last-of-type {
	border-right: none;
}

footer .wrap div:last-of-type p {
	padding: 0.5rem 0rem 0.5rem 1.25rem;
}

footer .wrap div:first-of-type p {
	padding: 0.5rem 1rem 0.5rem 0.5rem;
}

footer .wrap div p {
	font-size: .8rem;
	padding: 0.5rem 1.25rem;
}

footer .wrap div p a {
	color: #FFF;
}

footer .wrap div p a:hover {
	color: #FEC10D;
}

footer .wrap div p a span {
	vertical-align: middle;
}

footer .wrap div p a i {
	margin-left: .5rem;
	background-color: #FFF;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	color: #333;
	font-size: 1rem;
	vertical-align: middle;
	text-align: center;
	line-height: 1.4rem;
}

footer .wrap div ul {
	margin: 0 0 0 2rem;
}

footer .wrap div ul li {
	float: left;
	width: 31%;
	font-size: .8rem;
}

.mp-grid .mp-3 {
	width: 31.7%;
	float: left;
	margin-left: 1.2%;
	margin-right: 1.2%;
	margin-bottom: 1.2%;
}

.mp-grid .mp-3:nth-child(1),
.mp-grid .mp-3:nth-child(4),
.mp-grid .mp-3:nth-child(7),
.mp-grid .mp-3:nth-child(10),
.mp-grid .mp-3:nth-child(13),
.mp-grid .mp-3:nth-child(16),
.mp-grid .mp-3:nth-child(19) {
	margin-left: 0;
}

.mp-grid .mp-3:nth-child(3n+0) {
	margin-right: 0;
}

.mp-grid .mp-4 {
	width: 23.2%;
	float: left;
	margin-left: 1.2%;
	margin-right: 1.2%;
	margin-bottom: 1.2%;
}

.mp-grid .mp-4:nth-child(1),
.mp-grid .mp-4:nth-child(5),
.mp-grid .mp-4:nth-child(9),
.mp-grid .mp-4:nth-child(13),
.mp-grid .mp-4:nth-child(17),
.mp-grid .mp-4:nth-child(20),
.mp-grid .mp-4:nth-child(24) {
	margin-left: 0;
}

.mp-grid .mp-4:nth-child(4n+0) {
	margin-right: 0;
}

.mp-grid .mp-5 {
	width: 18%;
	float: left;
	margin-left: 1.2%;
	margin-right: 1.2%;
	margin-bottom: 1.2%;
}

.flex--container {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.flex--dir-row {
	flex-direction: row;
	flex: 0 1 auto;
	flex-wrap: wrap;
	margin-right: -.5rem;
	margin-left: -.5rem;
}

.flex--jc-start {
	justify-content: flex-start;
}

.flex--jc-center {
	justify-content: flex-center;
	justify-content: center;
}

.flex--jc-end {
	justify-content: flex-end;
}

.flex--jc-around {
	justify-content: space-around;
}

.flex--jc-between {
	justify-content: space-between;
}

.flex--col,
.flex--col-1,
.flex--col-2,
.flex--col-3,
.flex--col-4,
.flex--col-5,
.flex--col-6,
.flex--col-7,
.flex--col-8,
.flex--col-9,
.flex--col-10,
.flex--col-11,
.flex--col-12 {
	box-sizing: border-box;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-right: .5rem;
	padding-left: .5rem;
}

.flex--col {
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0;
	-webkit-flex-basis: 0;
	flex-basis: 0;
	max-width: 100%;
}

.flex--col-1 {
	-ms-flex-preferred-size: 8.333%;
	-webkit-flex-basis: 8.333%;
	flex-basis: 8.333%;
	max-width: 8.333%;
}

.flex--col-2 {
	-ms-flex-preferred-size: 16.667%;
	-webkit-flex-basis: 16.667%;
	flex-basis: 16.667%;
	max-width: 16.667%;
}

.flex--col-3 {
	-ms-flex-preferred-size: 25%;
	-webkit-flex-basis: 25%;
	flex-basis: 25%;
	max-width: 25%;
}

.flex--col-4 {
	-ms-flex-preferred-size: 33.333%;
	-webkit-flex-basis: 33.333%;
	flex-basis: 33.333%;
	max-width: 33.333%;
}

.flex--col-5 {
	-ms-flex-preferred-size: 41.667%;
	-webkit-flex-basis: 41.667%;
	flex-basis: 41.667%;
	max-width: 41.667%;
}

.flex--col-6 {
	-ms-flex-preferred-size: 50%;
	-webkit-flex-basis: 50%;
	flex-basis: 50%;
	max-width: 50%;
}

.flex--col-7 {
	-ms-flex-preferred-size: 58.333%;
	-webkit-flex-basis: 58.333%;
	flex-basis: 58.333%;
	max-width: 58.333%;
}

.flex--col-8 {
	-ms-flex-preferred-size: 66.667%;
	-webkit-flex-basis: 66.667%;
	flex-basis: 66.667%;
	max-width: 66.667%;
}

.flex--col-9 {
	-ms-flex-preferred-size: 75%;
	-webkit-flex-basis: 75%;
	flex-basis: 75%;
	max-width: 75%;
}

.flex--col-10 {
	-ms-flex-preferred-size: 83.333%;
	-webkit-flex-basis: 83.333%;
	flex-basis: 83.333%;
	max-width: 83.333%;
}

.flex--col-11 {
	-ms-flex-preferred-size: 91.667%;
	-webkit-flex-basis: 91.667%;
	flex-basis: 91.667%;
	max-width: 91.667%;
}

.flex--col-12 {
	-ms-flex-preferred-size: 100%;
	-webkit-flex-basis: 100%;
	flex-basis: 100%;
	max-width: 100%;
}

table {
	width: 100%;
}

table th {
	text-align: left;
	padding: .5rem;
}

table td {
	padding: .5rem;
}

nav {
	display: block;
	list-style: none;
	float: right;
}

nav .active {
	color: #0077C5;
}

nav ul {
	margin: 0;
}

nav ul li {
	display: inline-block;
	float: left;
	margin-right: 1rem;
	line-height: 3rem;
	position: relative;
	z-index: 99999;
	background-color: #0077C5;
}

nav ul li:last-of-type {
	margin-right: 0;
}

nav ul li:last-of-type a {
	border-right: none;
	padding-right: 0;
}

nav ul li:hover > ul {
	visibility: visible;
}

nav ul li:hover > a {
	color: #FEC10D;
}

nav ul li ul {
	visibility: hidden;
	position: absolute;
	top: 100%;
	left: 0;
	width: 12rem;
	margin-left: 0;
}

nav ul li ul li {
	float: none;
	display: block;
	margin-right: 0;
	line-height: 2rem;
}

nav ul li ul li a {
	border-right: none;
	padding-right: 0;
	padding-left: 1rem;
	display: block;
}

nav a {
	color: #FFF;
	border-right: 1px solid #FFF;
	padding-right: 1rem;
}

nav a:hover {
	color: #FEC10D !important;
}

#mobile-menu {
	margin: .5rem;
	padding: .5rem;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	background: #0077C5;
	color: #FFF;
	position: absolute;
	width: 1.75em;
	display: none;
}

#mobile-menu:before {
	content: "";
	position: absolute;
	left: 0.3rem;
	top: 0.25em;
	width: 1.15em;
	height: 0.25em;
	background: white;
	box-shadow: 0 0.38em 0 0 white, 0 0.75em 0 0 white;
}

aside > h3 i {
	font-size: 2.25rem !important;
	color: #FEC10D;
	float: right;
	padding: 0 0.25rem 0.25rem;
}

aside .sisea-search-form fieldset label {
	display: none;
}

aside .sisea-search-form fieldset input[type="text"] {
	background: transparent;
	border: 1px solid #FFF;
	text-indent: .25rem;
	color: #0077C5;
	width: 85%;
}

aside .sisea-search-form fieldset input[type="submit"] {
	background: transparent url("images/search-btn.svg");
	width: 26px;
	height: 26px;
	vertical-align: middle;
	cursor: pointer;
	font-size: 0;
	text-indent: -9999;
}

aside .toc {
	list-style: none;
	margin: .5rem 0;
}

aside .toc a {
	color: #FFF;
}

aside .toc a:hover {
	color: #FEC10D;
}

.hmpg-features .aside--feature {
	float: left;
	width: 32%;
	padding: 1% 2%;
}

.aside--feature {
	text-align: center;
	margin-bottom: 2rem;
}

.aside--feature i.fa-2x {
	font-size: 3rem;
}

.aside--feature h3 {
	color: #FEC10D;
	font-weight: bold;
	line-height: 2rem;
	padding-top: .5rem;
}

.aside--feature p {
	padding-bottom: 1rem;
}

#sliderMasthead {
	position: relative;
}

#full-slides {
	width: 100%;
	position: relative;
	z-index: 1;
	overflow: hidden;
	height: 600px;
}

#full-slides div {
	position: absolute;
	width: 100%;
	text-align: center;
}

#full-slides div img {
	max-width: 100%;
}

#fs-slide1 {
	z-index: 10;
}

#fs-slide2 {
	z-index: 9;
}

#fs-slide3 {
	z-index: 8;
}

#fs-slide4 {
	z-index: 7;
}

#fs-slide5 {
	z-index: 6;
}

#fs-slide6 {
	z-index: 5;
}

#fs-slide7 {
	z-index: 4;
}

#fs-slide8 {
	z-index: 3;
}

#fs-slide9 {
	z-index: 2;
}

#promo-slides {
	z-index: 99;
	position: absolute;
	top: 0;
	width: 100%;
}

#promo-slides .inner {
	width: 431px;
	height: 99px;
	position: relative;
	display: none;
	text-align: center;
	background: #aaa url("images/brushed-plate.jpg");
}

#promo-slides .inner.promo-slides-right {
	position: absolute;
	right: 0;
	top: 0;
	margin: 9% 11% 1% 0;
}

#promo-slides .inner.promo-slides-left {
	position: absolute;
	left: 0;
	top: 0;
	margin: 9% 0 1% 11%;
}

#promo-slides .inner.promo-slides-center {
	margin: 9% auto 0 auto;
}

#promo-slides #promo-slide1 {
	z-index: 10;
}

#promo-slides #promo-slide2 {
	z-index: 9;
}

#promo-slides #promo-slide3 {
	z-index: 8;
}

#promo-slides #promo-slide4 {
	z-index: 7;
}

#promo-slides #promo-slide5 {
	z-index: 6;
}

#promo-slides #promo-slide6 {
	z-index: 5;
}

#promo-slides #promo-slide7 {
	z-index: 4;
}

#promo-slides #promo-slide8 {
	z-index: 3;
}

#promo-slides #promo-slide9 {
	z-index: 2;
}

#promo-slides h1 {
	color: #000;
	font-size: 2rem;
	line-height: 2rem;
	padding-top: .5rem;
	letter-spacing: 2px;
}

#promo-slides h1:before {
	content: "=";
}

#promo-slides h1:after {
	content: "=";
}

#promo-slides p {
	color: #000;
	font-size: 1rem;
	padding: 0 0.75rem;
}

@media screen {

[hidden~="screen"] {
	display: inherit;
}

[hidden~="screen"]:not(:active):not(:focus):not(:target) {
	clip: rect(0 0 0 0) !important;
	position: absolute !important;
}

}

@media only screen and (min-width: 961px) {

.mp-grid .mp-5:nth-child(1),
.mp-grid .mp-5:nth-child(6),
.mp-grid .mp-5:nth-child(11),
.mp-grid .mp-5:nth-child(16),
.mp-grid .mp-5:nth-child(21),
.mp-grid .mp-5:nth-child(26),
.mp-grid .mp-5:nth-child(31),
.mp-grid .mp-5:nth-child(36),
.mp-grid .mp-5:nth-child(41),
.mp-grid .mp-5:nth-child(46),
.mp-grid .mp-5:nth-child(51),
.mp-grid .mp-5:nth-child(56),
.mp-grid .mp-5:nth-child(61),
.mp-grid .mp-5:nth-child(66),
.mp-grid .mp-5:nth-child(71),
.mp-grid .mp-5:nth-child(76),
.mp-grid .mp-5:nth-child(81),
.mp-grid .mp-5:nth-child(86) {
	margin-left: 0;
}

.mp-grid .mp-5:nth-child(5n+0) {
	margin-right: 0;
}

}

@media only screen and (max-width: 960px) {

.h1 {
	font-size: 2rem;
	line-height: 2rem;
}

.h2 {
	font-size: 1.5rem;
	line-height: 1.5rem;
}

.m-responsive {
	margin: 3rem;
}

.mt-responsive {
	margin-top: 3rem;
}

.mr-responsive {
	margin-right: 3rem;
}

.mb-responsive {
	margin-bottom: 3rem;
}

.ml-responsive {
	margin-left: 3rem;
}

.p-responsive {
	padding: 3rem;
}

.px-responsive {
	padding-right: 3rem;
	padding-left: 3rem;
}

.py-responsive {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.tablet-show,
.tablet-block {
	display: block !important;
}

.tablet-hide {
	display: none;
}

.tablet-base {
	margin: 1rem !important;
	padding: 1rem !important;
}

header #logo {
	padding-left: 3rem;
	padding-top: .5rem;
	padding-bottom: .5rem;
}

header #logo a {
	font-size: 1.25rem;
}

header .top--links {
	padding-right: 1rem;
}

.hero .wrap h1 {
	padding-left: 1rem;
}

main > .wrap {
	background: transparent;
}

main article {
	width: 100%;
	float: none;
}

main aside {
	width: 100%;
	float: none;
	padding: 0;
}

.mp-grid .mp-5 {
	width: 31.2%;
	overflow: hidden;
}

.mp-grid .mp-5:nth-child(1),
.mp-grid .mp-5:nth-child(4),
.mp-grid .mp-5:nth-child(7),
.mp-grid .mp-5:nth-child(10),
.mp-grid .mp-5:nth-child(13),
.mp-grid .mp-5:nth-child(16),
.mp-grid .mp-5:nth-child(19),
.mp-grid .mp-5:nth-child(22),
.mp-grid .mp-5:nth-child(25),
.mp-grid .mp-5:nth-child(28),
.mp-grid .mp-5:nth-child(31),
.mp-grid .mp-5:nth-child(34),
.mp-grid .mp-5:nth-child(37),
.mp-grid .mp-5:nth-child(40),
.mp-grid .mp-5:nth-child(43),
.mp-grid .mp-5:nth-child(47),
.mp-grid .mp-5:nth-child(50),
.mp-grid .mp-5:nth-child(53) {
	margin-left: 0;
}

.mp-grid .mp-5:nth-child(3n+0) {
	margin-right: 0;
}

nav {
	display: none;
	float: none;
	clear: left;
}

nav ul li {
	display: block;
	float: none;
	margin: 0;
	line-height: 3rem;
}

nav ul li a {
	display: block;
	padding: 0 1rem;
	border-bottom: 1px solid #FFF;
	border-right: none;
}

nav ul li ul {
	visibility: visible;
	position: static;
	width: 100%;
}

nav ul li ul li {
	line-height: 3rem;
}

nav ul li ul li a {
	display: block;
}

#mobile-menu {
	display: block;
}

aside .sisea-search-form fieldset input[type="text"] {
	width: 50%;
}

.aside--feature {
	width: 32%;
	float: left;
	padding: 1% 2%;
}

}

@media only screen and (max-width: 950px) {

footer .wrap div {
	width: 100% !important;
	float: none;
	border-right: none;
	min-height: 1rem;
}

}

@media only screen and (max-width: 680px) {

.m-responsive {
	margin: 2rem;
}

.mt-responsive {
	margin-top: 2rem;
}

.mr-responsive {
	margin-right: 2rem;
}

.mb-responsive {
	margin-bottom: 2rem;
}

.ml-responsive {
	margin-left: 2rem;
}

.p-responsive {
	padding: 1.5rem;
}

.px-responsive {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}

.py-responsive {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.mobile-show,
.mobile-block {
	display: block !important;
}

.mobile-block {
	width: 100% !important;
}

.mobile-hide {
	display: none;
}

.mobile-center {
	text-align: center;
}

.mobile-base {
	margin: 1rem !important;
	padding: 1rem !important;
}

.contactForm div label {
	width: auto;
	display: block;
}

.contactForm div .input,
.contactForm div .textarea {
	width: 100%;
}

.contactForm button {
	margin-left: 0px;
	margin-bottom: 1.5rem;
}

.gutterx2 {
	padding: .5rem;
}

.hero {
	height: 12rem;
}

.hero .wrap h1 {
	font-size: 3rem;
}

.mp-grid .mp-3 {
	width: 100%;
	margin-right: 0;
	margin-left: 0;
}

.mp-grid .mp-4 {
	width: 100%;
	margin-right: 0;
	margin-left: 0;
}

.mp-grid .mp-5 {
	width: 100%;
	height: auto;
	margin-right: 0;
	margin-left: 0;
}

.flex--col,
.flex--col-1,
.flex--col-2,
.flex--col-3,
.flex--col-4,
.flex--col-5,
.flex--col-6,
.flex--col-7,
.flex--col-8,
.flex--col-9,
.flex--col-10,
.flex--col-11,
.flex--col-12 {
	-ms-flex-preferred-size: 100%;
	-webkit-flex-basis: 100%;
	flex-basis: 100%;
	max-width: 100%;
}

.aside--feature {
	width: 100% !important;
	float: none !important;
	padding: 5% !important;
	margin-top: 2rem !important;
	border-top: 1px solid #FFF !important;
}

#promo-slides {
	position: static;
	padding: 1.5rem;
	text-align: center;
}

#promo-slides .inner {
	position: static !important;
	margin: 0 auto !important;
}

}

@media only screen and (max-width: 600px) {

header #logo a {
	font-size: 1rem;
}

header .top--links span {
	font-size: 1rem;
}

}

@media only screen and (max-width: 480px) {

header #logo {
	padding-left: 2.2rem;
}

header .top--links span {
	padding-right: 0;
}

header .top--links span,
header .top--links a#paylink {
	display: none;
}

header .top--links span#phoneText {
	display: inline !important;
}

footer .wrap div ul li {
	width: 49%;
}

#promo-slides .inner {
	width: 100%;
	background: #aaa;
	height: auto;
}

}

@media only screen and (max-width: 60rem) {

main {
	margin: 0 1rem !important;
}

}

